import React from "react";
import Layout from "../components/Page/Layout";
import FullPageError from "../components/FullPageError";

const FourOhFour = () => (
  <Layout>
    <FullPageError errorCode="404" />
  </Layout>
);

export default FourOhFour;
